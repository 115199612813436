@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;600;700&family=Dosis:wght@200&family=Shadows+Into+Light&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@200&display=swap');

@import 'animate.css';

body {
  font-family: 'Saira Condensed', sans-serif;
}


/* ANIMATION CONTATTI PAGE */
.contactItem:nth-of-type(1) {
  animation-delay: 0.1s;
}
.contactItem:nth-of-type(2) {
  animation-delay: 0.2s;
}
.contactItem:nth-of-type(3) {
  animation-delay: 0.3s;
}
.contactItem:nth-of-type(4) {
  animation-delay: 0.3s;
}

/* ANIMATION FAQ PAGE */
.faqItem:nth-of-type(1) {
  animation-delay: 0.1s;
}
.faqItem:nth-of-type(2) {
  animation-delay: 0.2s;
}
.faqItem:nth-of-type(3) {
  animation-delay: 0.3s;
}
.faqItem:nth-of-type(4) {
  animation-delay: 0.4s;
}
.faqItem:nth-of-type(5) {
  animation-delay: 0.5s;
}
.faqItem:nth-of-type(6) {
  animation-delay: 0.6s;
}
.faqItem:nth-of-type(7) {
  animation-delay: 0.7s;
}
.faqItem:nth-of-type(8) {
  animation-delay: 0.8s;
}


.map-container {
  opacity:0;
  animation: fadeInRight 1s;
  animation-fill-mode: forwards;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  margin: 10px auto;
} 

/* TENDINA COLLAPSE */
.collapse-arrow .collapse-title:after {
  height: 1rem;
  width: 1rem;
}

.loader {
  animation: fadeOut 1s 0.5s;
  animation-fill-mode: forwards;
}


/* MOBILE */
@media screen and (max-width: 550px){
  .tooltip::before {
    padding: 0 55px;
  }
}



/* DESKTOP */
@media screen and (min-width: 760px) {
  .social-elem:hover .social-qr {
    display: block;
  }
}

@media screen and (min-width: 1024px){
  .writing-mode {
    writing-mode: vertical-rl;
  }
}
