@import 'animate.css';

.text-animate {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  /*evita che dopo l'animazione ritorni l'opacity a 0*/
  min-width: 10px;
}

.subtitle-animate {
  opacity: 0;
  animation: fadeInRight 1s;
  animation-fill-mode: forwards;
  /*evita che dopo l'animazione ritorni l'opacity a 0*/
  min-width: 10px;
}


/* loop per la classe text-animate _idx*/
@for $i from 1 through 35 {
  .text-animate._#{$i} {
    animation-delay: #{$i / 10 + 1}s;
    /* per ogni lettera c'è un ritardo dell'animazione di 1/10 di secondo */
  }
}

/* loop per la classe subtitle-animate _idx page Azienda*/
@for $i from 1 through 300 {
  .subtitle-animate._#{$i} {
    animation-delay: #{$i / 65 }s;
    /* per ogni lettera c'è un ritardo dell'animazione di 1/10 di secondo */
  }
}